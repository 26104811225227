'use client';

import { AbsoluteCenter, ErrorPage, ErrorPageButton, ErrorPageText } from '@/components';
import { ROUTES } from '@/config';

/**
 * The component is used to display an error message in the signin area of the application.
 */
export const AuthError: React.FC = () => {
  return (
    <AbsoluteCenter>
      <ErrorPage>
        <ErrorPageText />

        <ErrorPageButton href={ROUTES.AUTH.SIGNIN} />
      </ErrorPage>
    </AbsoluteCenter>
  );
};

export default AuthError;
